/*
 | Making a table header stick on top of screen when scrolling.
 |
 */

import {debounce} from "lodash/function";

(function () {

    /**
     * Table wrappers on current page.
     *
     * @type {NodeListOf<HTMLDivElement>}
     */
    const WRAPPERS = document.querySelectorAll('div.sticky-table-wrapper');

    /**
     * Offset to stick to from top viewport.
     *
     * @type {int}
     */
    const THRESHOLD = (() => {
        const t = window.getComputedStyle(document.documentElement).getPropertyValue('--navbar-height');
        return +(t.replace('px', ''));
    })();

    WRAPPERS.forEach(w => {
        window.addEventListener(
            'scroll',
            debounce(setTableHeaderPosition.bind(w), 100)
        );
    });

    /**
     * Calculate and set the sticky table header position.
     *
     * @return {void}
     */
    function setTableHeaderPosition() {
        const tableOffset = this.querySelector('table').getBoundingClientRect().top;
        const offset = () => {
            if (tableOffset > THRESHOLD) return 0;
            if (tableOffset > 0) return THRESHOLD - tableOffset;
            return Math.abs(tableOffset) + THRESHOLD;
        }

        document.documentElement.style.setProperty('--sticky-table-offset', `${offset()}px`);
    }

})();