require('./bootstrap');
require('./sticky-table-headers');

window.addEventListener('load', e => {


/**
 * Side navigation menu.
 */
const wrapper = document.getElementById('app');
const menuToggler = document.getElementById('side-menu-toggler');
document.getElementById('main').style.transition = 'all 0.2s ease';
menuToggler.onclick = e => {
    e.preventDefault();
    menuToggler.classList.toggle('open');
    wrapper.classList.toggle('menu-open');
    const isOpen = (window.innerWidth < 992) ? false : menuToggler.classList.contains('open');
    document.cookie = `menu-open=${!isOpen ? 'no' : 'yes'}; expires=Sun, 1 Jan 2100 00:00:00 UTC; path=/`;
}

/**
 * Initiate tooltips wherever found.
 */
$(function () {
    $('[data-toggle="tooltip"]').tooltip();
});

/**
 * Initiate ".all-selector" checkboxes wherever found.
 */
document.querySelectorAll('.all-selector').forEach(as => {
    as.onchange = e => {
        const family = document.querySelectorAll(`input[type="checkbox"][name="${as.name}"]`);
        family.forEach(box => box.checked = as.checked);
    }
});

/**
 * Initiate ".period-selector" buttons wherever found.
 */
document.querySelectorAll('.period-selector').forEach(ps => {
    ps.onclick = e => {
        e.preventDefault();
        const dFrom = ps.dataset.period.split('+')[0];
        const dTo = ps.dataset.period.split('+')[1];
        const inputFrom = ps.closest('form').querySelector(`input[type="date"][name="${ps.dataset.target.split(':')[0]}"]`);
        const inputTo = ps.closest('form').querySelector(`input[type="date"][name="${ps.dataset.target.split(':')[1]}"]`);
        inputFrom.value = dFrom;
        inputTo.value = dTo;
    }
});

/**
 * Initiate ".custom-toggle" buttons wherever found.
 */
document.querySelectorAll('.custom-toggle').forEach(ct => {
    const checkbox = ct.querySelector('input[type="checkbox"]');
    const output = ct.querySelector('span.output');
    let collapse = false;
    if (ct.classList.contains('collapse-appended')) {
        collapse = '#' + checkbox.name + '-toggle-collapse';
    }
    checkbox.onchange = e => {
        if (e.target.checked) {
            if (output) {
                output.innerText = 'ON';
            }
            if (collapse) {
                $(collapse).collapse('show');
            }
        } else {
            if (output) {
                output.innerText = 'OFF';
            }
            if (collapse) {
                $(collapse).collapse('hide');
            }
        }
    }

});

/**
 * Configuring FancyBox images lightbox.
 */
$.fancybox.defaults.parentEl = "#main";
$.fancybox.defaults.loop = true;
$.fancybox.defaults.hash = false;
$.fancybox.defaults.backFocus = false;
$.fancybox.defaults.buttons = ["close", "zoom"];

/**
 * Toggle night mode.
 */
function setColorScheme(dark)
{
    let method = dark ? 'add' : 'remove';
    document.documentElement.classList[method]('dark-mode-enable');
    document.getElementById('color-mode').classList[method]('dark');
}


const nightModeCookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('night-mode='));

window.nightModeActive = !nightModeCookie ? false : (nightModeCookie.split('=')[1] === 'on');

setColorScheme(window.nightModeActive);

window.toggleNightMode = () => {
    document.body.classList.add('transitioning');

    window.nightModeActive = !window.nightModeActive;
    setColorScheme(window.nightModeActive);
    document.cookie = `night-mode=${!window.nightModeActive ? 'off' : 'on'}; expires=Sun, 1 Jan 2100 00:00:00 UTC; path=/`;

    setTimeout(() => {
        document.body.classList.remove('transitioning');
    }, 300)
}


/**
 * Submit forms when input is changed.
 */
const csubmit = new Event('csubmit');
const submitOnChange = document.querySelectorAll('.submit-on-change');
if (submitOnChange.length) {
    submitOnChange.forEach(input => {
        input.onchange = e => {
            input.closest('form').dispatchEvent(csubmit);
            $(input).closest('form').submit();
        }
    });
}

/**
 * Strip empty parameters from url when a form is submitted.
 */
function submitForm(form, e) {
    console.log(e);
    e.preventDefault();
    let data = {};
    new FormData(form).forEach((val, key) => {
        console.log(val, key);
        if (val !== null && val !== '') {
            if (key.endsWith('[]')) {
                if (!data[key]) {
                    data[key] = [];
                }
                data[key].push(val);
            } else {
                data[key] = val;
            }
        }
    });
    let url = window.location.origin + window.location.pathname
    if (Object.keys(data).length) {
        url = url + '?' + $.param(data);
    }
    window.location.href = url;
}
document.querySelectorAll('form').forEach(form => {
    if (form.method === 'get') {
        form.addEventListener('submit', e => {
            submitForm(form, e);
        });
    }

    form.addEventListener('csubmit', e => {
        submitForm(form, e);
    });
});
});